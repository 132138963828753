import * as actionTypes from './actionTypes';
import { PaymentActionTypes } from './actionTypes';
import { HttpError } from '../../config/Axios/axios-instance';
import { ListResults } from '../../common/List/List';
import { Payment } from '../../domain/Payment';

export type PaymentStateType = {
  paymentsList: ListResults<Payment> | null;
  paymentsLoading: boolean;
  paymentsError: HttpError;
  paymentListUpdateNeeded: boolean;
  sendPaymentsLoading: boolean;
  sendPaymentsError: HttpError;
  regeneratePaymentsLoading: boolean;
  regeneratePaymentsError: HttpError;
  downloadInvoiceLoading: boolean;
  downloadInvoiceError: HttpError;
  downloadInvoiceAdditionLoading: boolean;
  downloadInvoiceAdditionError: HttpError;
  updateInvoiceLinesError: HttpError;
  updateInvoiceLinesLoading: boolean;
  downloadInvoiceBatchLoading: boolean;
  downloadInvoiceBatchError: HttpError;
  downloadInvoiceAdditionBatchLoading: boolean;
  downloadInvoiceAdditionBatchError: HttpError;
  togglePaymentLockStatusLoading: boolean;
  togglePaymentLockStatusSuccess: boolean;
  togglePaymentLockStatusError: HttpError;
  createdPayment: Payment | null;
  createPaymentLoading: boolean;
  createPaymentError: HttpError;
  paymentDeleteLoading: boolean;
  paymentDeleteError: HttpError;
  paymentDeleteSuccess: boolean;
  bulkPaymentsDeleteLoading: boolean;
  bulkPaymentsDeleteError: HttpError;
  bulkPaymentsDeleteSuccess: boolean;
};

export type PaymentActionType = PaymentStateType & {
  type: PaymentActionTypes;
};

export const initialState: PaymentStateType = {
  paymentsList: null,
  paymentsLoading: true,
  paymentsError: null,
  paymentListUpdateNeeded: false,
  sendPaymentsLoading: false,
  sendPaymentsError: null,
  regeneratePaymentsLoading: false,
  regeneratePaymentsError: null,
  downloadInvoiceError: null,
  downloadInvoiceLoading: false,
  downloadInvoiceAdditionError: null,
  downloadInvoiceAdditionLoading: false,
  updateInvoiceLinesError: null,
  updateInvoiceLinesLoading: false,
  downloadInvoiceBatchError: null,
  downloadInvoiceBatchLoading: false,
  downloadInvoiceAdditionBatchError: null,
  downloadInvoiceAdditionBatchLoading: false,
  togglePaymentLockStatusLoading: false,
  togglePaymentLockStatusSuccess: false,
  togglePaymentLockStatusError: null,
  createdPayment: null,
  createPaymentLoading: false,
  createPaymentError: null,
  paymentDeleteLoading: false,
  paymentDeleteError: null,
  paymentDeleteSuccess: false,
  bulkPaymentsDeleteLoading: false,
  bulkPaymentsDeleteError: null,
  bulkPaymentsDeleteSuccess: false,
};

const fetchPaymentsStart = (state: PaymentStateType): PaymentStateType => ({
  ...state,
  paymentsLoading: true,
});

const fetchPaymentsSuccess = (
  state: PaymentStateType,
  action: PaymentActionType,
): PaymentStateType => ({
  ...state,
  paymentsList: action.paymentsList,
  paymentsLoading: false,
  paymentsError: null,
  paymentListUpdateNeeded: false,
});

const fetchPaymentsFail = (
  state: PaymentStateType,
  action: PaymentActionType,
): PaymentStateType => ({
  ...state,
  paymentsError: action.paymentsError,
  paymentsLoading: false,
});

const sendPaymentsStart = (state: PaymentStateType): PaymentStateType => ({
  ...state,
  sendPaymentsLoading: true,
});

const sendPaymentsSuccess = (state: PaymentStateType): PaymentStateType => ({
  ...state,
  sendPaymentsLoading: false,
  sendPaymentsError: null,
  paymentListUpdateNeeded: true,
});

const sendPaymentsFail = (
  state: PaymentStateType,
  action: PaymentActionType,
): PaymentStateType => ({
  ...state,
  sendPaymentsError: action.sendPaymentsError,
  sendPaymentsLoading: false,
});

const regeneratePaymentsStart = (
  state: PaymentStateType,
): PaymentStateType => ({
  ...state,
  regeneratePaymentsLoading: true,
});

const regeneratePaymentsSuccess = (
  state: PaymentStateType,
): PaymentStateType => ({
  ...state,
  regeneratePaymentsLoading: false,
  regeneratePaymentsError: null,
  paymentListUpdateNeeded: true,
});

const regeneratePaymentsFail = (
  state: PaymentStateType,
  action: PaymentActionType,
): PaymentStateType => ({
  ...state,
  regeneratePaymentsError: action.regeneratePaymentsError,
  regeneratePaymentsLoading: false,
});

const downloadInvoiceStart = (state: PaymentStateType): PaymentStateType => ({
  ...state,
  downloadInvoiceLoading: true,
});

const downloadInvoiceSuccess = (state: PaymentStateType): PaymentStateType => ({
  ...state,
  downloadInvoiceLoading: false,
  downloadInvoiceError: null,
});

const downloadInvoiceFail = (
  state: PaymentStateType,
  action: PaymentActionType,
): PaymentStateType => ({
  ...state,
  downloadInvoiceError: action.downloadInvoiceError,
  downloadInvoiceLoading: false,
});

const downloadInvoiceBatchStart = (
  state: PaymentStateType,
): PaymentStateType => ({
  ...state,
  downloadInvoiceBatchLoading: true,
});

const downloadInvoiceBatchSuccess = (
  state: PaymentStateType,
): PaymentStateType => ({
  ...state,
  downloadInvoiceBatchLoading: false,
  downloadInvoiceBatchError: null,
});

const downloadInvoiceBatchFail = (
  state: PaymentStateType,
  action: PaymentActionType,
): PaymentStateType => ({
  ...state,
  downloadInvoiceBatchError: action.downloadInvoiceError,
  downloadInvoiceBatchLoading: false,
});

const downloadInvoiceAdditionStart = (
  state: PaymentStateType,
): PaymentStateType => ({
  ...state,
  downloadInvoiceAdditionLoading: true,
});

const downloadInvoiceAdditionSuccess = (
  state: PaymentStateType,
): PaymentStateType => ({
  ...state,
  downloadInvoiceAdditionLoading: false,
  downloadInvoiceAdditionError: null,
});

const downloadInvoiceAdditionFail = (
  state: PaymentStateType,
  action: PaymentActionType,
): PaymentStateType => ({
  ...state,
  downloadInvoiceAdditionError: action.downloadInvoiceAdditionError,
  downloadInvoiceAdditionLoading: false,
});

const updateInvoiceLinesStart = (
  state: PaymentStateType,
): PaymentStateType => ({
  ...state,
  updateInvoiceLinesLoading: true,
});

const updateInvoiceLinesSuccess = (
  state: PaymentStateType,
): PaymentStateType => ({
  ...state,
  updateInvoiceLinesLoading: false,
  updateInvoiceLinesError: null,
  paymentListUpdateNeeded: true,
});

const updateInvoiceLinesFail = (
  state: PaymentStateType,
  action: PaymentActionType,
): PaymentStateType => ({
  ...state,
  updateInvoiceLinesError: action.updateInvoiceLinesError,
  updateInvoiceLinesLoading: false,
});

const downloadInvoiceAdditionBatchStart = (
  state: PaymentStateType,
): PaymentStateType => ({
  ...state,
  downloadInvoiceAdditionBatchLoading: true,
});

const downloadInvoiceAdditionBatchSuccess = (
  state: PaymentStateType,
): PaymentStateType => ({
  ...state,
  downloadInvoiceAdditionBatchLoading: false,
  downloadInvoiceAdditionBatchError: null,
});

const downloadInvoiceAdditionBatchFail = (
  state: PaymentStateType,
  action: PaymentActionType,
): PaymentStateType => ({
  ...state,
  downloadInvoiceAdditionBatchError: action.downloadInvoiceAdditionBatchError,
  downloadInvoiceAdditionBatchLoading: false,
});

const togglePaymentLockStatusStart = (
  state: PaymentStateType,
): PaymentStateType => ({
  ...state,
  togglePaymentLockStatusLoading: true,
});

const togglePaymentLockStatusSuccess = (
  state: PaymentStateType,
): PaymentStateType => ({
  ...state,
  togglePaymentLockStatusLoading: false,
  togglePaymentLockStatusSuccess: true,
  paymentListUpdateNeeded: true,
});

const togglePaymentLockStatusFail = (
  state: PaymentStateType,
  action: PaymentActionType,
): PaymentStateType => ({
  ...state,
  togglePaymentLockStatusError: action.sendPaymentsError,
  togglePaymentLockStatusLoading: false,
  togglePaymentLockStatusSuccess: false,
});

const generatePaymentStart = (state: PaymentStateType): PaymentStateType => ({
  ...state,
  createPaymentLoading: true,
});

const generatePaymentSuccess = (
  state: PaymentStateType,
  action: PaymentActionType,
): PaymentStateType => ({
  ...state,
  createdPayment: action.createdPayment,
  createPaymentLoading: false,
  createPaymentError: null,
  paymentListUpdateNeeded: true,
});

const generatePaymentFail = (
  state: PaymentStateType,
  action: PaymentActionType,
): PaymentStateType => ({
  ...state,
  createPaymentError: action.createPaymentError,
  createPaymentLoading: false,
});

const deletePaymentStart = (state: PaymentStateType): PaymentStateType => ({
  ...state,
  paymentDeleteLoading: true,
});

const deletePaymentSuccess = (state: PaymentStateType): PaymentStateType => ({
  ...state,
  paymentDeleteLoading: false,
  paymentDeleteError: null,
  paymentDeleteSuccess: true,
  paymentListUpdateNeeded: true,
});

const deletePaymentFail = (
  state: PaymentStateType,
  action: PaymentActionType,
): PaymentStateType => ({
  ...state,
  paymentDeleteLoading: false,
  paymentDeleteError: action.paymentDeleteError,
});

const deleteBulkPaymentStart = (state: PaymentStateType): PaymentStateType => ({
  ...state,
  bulkPaymentsDeleteLoading: true,
});

const deleteBulkPaymentSuccess = (
  state: PaymentStateType,
): PaymentStateType => ({
  ...state,
  bulkPaymentsDeleteLoading: false,
  bulkPaymentsDeleteError: null,
  bulkPaymentsDeleteSuccess: true,
  paymentListUpdateNeeded: true,
});

const deleteBulkPaymentFail = (
  state: PaymentStateType,
  action: PaymentActionType,
): PaymentStateType => ({
  ...state,
  bulkPaymentsDeleteLoading: false,
  bulkPaymentsDeleteError: action.bulkPaymentsDeleteError,
});

const resetPaymentStore = (): PaymentStateType => ({
  ...initialState,
});

const completelyResetPaymentStore = (): PaymentStateType => ({
  ...initialState,
});

const reducer = (state = initialState, action: PaymentActionType) => {
  switch (action.type) {
    case actionTypes.FETCH_PAYMENTS_START:
      return fetchPaymentsStart(state);
    case actionTypes.FETCH_PAYMENTS_SUCCESS:
      return fetchPaymentsSuccess(state, action);
    case actionTypes.FETCH_PAYMENTS_FAIL:
      return fetchPaymentsFail(state, action);
    case actionTypes.GENERATE_PAYMENT_START:
      return generatePaymentStart(state);
    case actionTypes.GENERATE_PAYMENT_SUCCESS:
      return generatePaymentSuccess(state, action);
    case actionTypes.GENERATE_PAYMENT_FAIL:
      return generatePaymentFail(state, action);
    case actionTypes.DELETE_PAYMENT_START:
      return deletePaymentStart(state);
    case actionTypes.DELETE_PAYMENT_SUCCESS:
      return deletePaymentSuccess(state);
    case actionTypes.DELETE_PAYMENT_FAIL:
      return deletePaymentFail(state, action);
    case actionTypes.DELETE_BULK_PAYMENTS_START:
      return deleteBulkPaymentStart(state);
    case actionTypes.DELETE_BULK_PAYMENTS_SUCCESS:
      return deleteBulkPaymentSuccess(state);
    case actionTypes.DELETE_BULK_PAYMENTS_FAIL:
      return deleteBulkPaymentFail(state, action);
    case actionTypes.SEND_PAYMENTS_START:
      return sendPaymentsStart(state);
    case actionTypes.SEND_PAYMENTS_SUCCESS:
      return sendPaymentsSuccess(state);
    case actionTypes.SEND_PAYMENTS_FAIL:
      return sendPaymentsFail(state, action);
    case actionTypes.DOWNLOAD_INVOICE_START:
      return downloadInvoiceStart(state);
    case actionTypes.DOWNLOAD_INVOICE_SUCCESS:
      return downloadInvoiceSuccess(state);
    case actionTypes.DOWNLOAD_INVOICE_FAIL:
      return downloadInvoiceFail(state, action);
    case actionTypes.DOWNLOAD_INVOICE_ADDITION_START:
      return downloadInvoiceAdditionStart(state);
    case actionTypes.DOWNLOAD_INVOICE_ADDITION_SUCCESS:
      return downloadInvoiceAdditionSuccess(state);
    case actionTypes.DOWNLOAD_INVOICE_ADDITION_FAIL:
      return downloadInvoiceAdditionFail(state, action);
    case actionTypes.DOWNLOAD_INVOICE_BATCH_START:
      return downloadInvoiceBatchStart(state);
    case actionTypes.DOWNLOAD_INVOICE_BATCH_SUCCESS:
      return downloadInvoiceBatchSuccess(state);
    case actionTypes.DOWNLOAD_INVOICE_BATCH_FAIL:
      return downloadInvoiceBatchFail(state, action);
    case actionTypes.UPDATE_INVOICE_LINES_START:
      return updateInvoiceLinesStart(state);
    case actionTypes.UPDATE_INVOICE_LINES_SUCCESS:
      return updateInvoiceLinesSuccess(state);
    case actionTypes.UPDATE_INVOICE_LINES_FAIL:
      return updateInvoiceLinesFail(state, action);
    case actionTypes.REGENERATE_PAYMENTS_START:
      return regeneratePaymentsStart(state);
    case actionTypes.REGENERATE_PAYMENTS_SUCCESS:
      return regeneratePaymentsSuccess(state);
    case actionTypes.REGENERATE_PAYMENTS_FAIL:
      return regeneratePaymentsFail(state, action);
    case actionTypes.DOWNLOAD_INVOICE_ADDITION_BATCH_START:
      return downloadInvoiceAdditionBatchStart(state);
    case actionTypes.DOWNLOAD_INVOICE_ADDITION_BATCH_SUCCESS:
      return downloadInvoiceAdditionBatchSuccess(state);
    case actionTypes.DOWNLOAD_INVOICE_ADDITION_BATCH_FAIL:
      return downloadInvoiceAdditionBatchFail(state, action);
    case actionTypes.TOGGLE_PAYMENT_LOCK_STATUS_START:
      return togglePaymentLockStatusStart(state);
    case actionTypes.TOGGLE_PAYMENT_LOCK_STATUS_SUCCESS:
      return togglePaymentLockStatusSuccess(state);
    case actionTypes.TOGGLE_PAYMENT_LOCK_STATUS_FAIL:
      return togglePaymentLockStatusFail(state, action);
    case actionTypes.RESET_PAYMENT_STORE:
      return resetPaymentStore();
    case actionTypes.LOGOUT:
      return completelyResetPaymentStore();
    default:
      return state;
  }
};

export default reducer;
